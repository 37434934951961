* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "regular";
}
@font-face {
  font-family: "bold";
  src: url(../font//Poppins\ Bold.ttf);
}
@font-face {
  font-family: "regular";
  src: url(../font/Poppins\ Regular.ttf);
}
:root {
  --primary: #ff4865;
  --secondary: #ff866a;
  --gradient: linear-gradient(to top, var(--primary), var(--secondary));
  --button-gradient: linear-gradient(to left, var(--primary), var(--secondary));
  --radius: 4px;
  --grey: #f9f9f9;
  --shadow: #00000029 0px 2px 5px;
  --transition: all 0.25s linear;
}
body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background: var(--grey);
}
h1 {
  font-size: 22px;
  margin: 0;
  font-family: "bold";
  text-transform: capitalize;
}
h2 {
  font-size: 18px;
  margin: 0;
  font-family: "bold";
  text-transform: capitalize;
}
h3 {
  font-size: 16px;
  margin: 0;
  font-family: "bold";
  text-transform: capitalize;
}
p,
a,
ul,
li,
label,
input,
select,
option,
button {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: "regular";
  border: none;
  outline: none;
  text-decoration: none;
  color: unset;
}
label {
  text-transform: capitalize;
  user-select: none;
  pointer-events: none;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-page {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.login-page .left-part,
.login-page .right-part {
  width: 50%;
  height: 100%;
}
.login-page .left-part {
  background: var(--gradient);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login-page .left-part .inner-part {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  width: 500px;
  height: 500px;
  margin-left: auto;
  padding: 50px;
}
.login-page .left-part h2,
.login-page .left-part p,
.login-page .left-part li {
  color: #fff;
}
.login-page .left-part .terms-list {
  list-style-type: none;
}
.login-page .left-part .terms-list li:nth-child(1) {
  padding-right: 10px;
}
.login-page .left-part .terms-list li:nth-child(2) {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.login-page .left-part .terms-list li:nth-child(3) {
  padding-left: 10px;
}
.login-page .right-part {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
}
.login-page .right-part label {
  background: #fff;
}
.login-page .right-part .inner-part {
  width: 500px;
  height: 500px;
  margin-right: auto;
  padding: 50px 100px 50px;
}
.login-page .right-part .login-form {
  max-width: 280px;
}
.login-page .right-part .login-form .button,
.login-page .right-part .login-form .black-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-page .right-part .login-form .button:hover svg,
.login-page .right-part .login-form .black-button:hover svg {
  transform: translateX(0);
}
.login-page .right-part .login-form .button svg,
.login-page .right-part .login-form .black-button svg {
  transition: var(--transition);
  transform: translateX(-10px);
}
.form-control {
  position: relative;
  width: 100%;
}
.form-label {
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  padding: 0 0.25rem;
  color: #333;
  background: var(--grey);
  transition: var(--transition);
}
.form-input {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: var(--radius);
  border: 1px solid #333;
  background: transparent;
  transition: var(--transition);
}
.form-input::-moz-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}
.form-input::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}
.form-input:focus ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 5;
  font-size: 0.875rem;
  color: #333;
  transition: var(--transition);
}

.form-input:not(:-moz-placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
  top: -0.75rem;
  left: 1rem;
  z-index: 9;
  font-size: 0.875rem;
  transition: var(--transition);
}
.logo {
  width: 150px;
}
.button {
  padding: 10px;
  text-align: left;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  background: var(--button-gradient);
  border-radius: var(--radius);
  transition: var(--transition);
}
.white-button {
  padding: 10px;
  background: #fff;
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
  border: 1px solid #000;
  border-radius: var(--radius);
  transition: var(--transition);
  text-align: center;
}
.white-button:hover {
  background: var(--button-gradient);
  color: #fff;
  border: 1px solid transparent;
}
.form-control {
  padding: 0;
  font-size: 1rem;
  font-weight: unset;
  line-height: unset;
  color: unset;
  background-color: unset;
  border: none;
  border-radius: 0 !important;
  transition: var(--transition);
  box-shadow: none !important;
  padding: 5px 0;
}
.form-control:focus {
  color: #000 !important;
  background-color: transparent !important;
  border-color: #000 !important;
  outline: none !important;
  box-shadow: none !important;
}
textarea.form-control {
  min-height: 50px;
  max-height: 200px;
  overflow-y: auto;
}
.links {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  list-style: none;
}
.links li {
  width: 100%;
}
.links li a {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow);
}
.links li a svg {
  font-size: 1rem;
}
.notice-board {
  border-radius: var(--radius);
  background: var(--button-gradient);
  box-shadow: var(--shadow);
}
.tab {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius);
  gap: 10px;
}
.tab li {
  width: 100%;
}
.tab li a {
  width: 100%;
  height: 60px;
  display: grid;
  place-items: center;
  box-shadow: var(--shadow);
}
.tab li a svg {
  font-size: 1rem;
}
.tab li a.active {
  background: var(--button-gradient);
  border: 1px solid var(--secondary);
  color: #fff;
}
.new-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: minmax(80px, auto);
  align-items: start;
  justify-content: space-between;
  gap: 10px;
}
.new-card {
  padding: 10px;
  border-radius: var(--radius);
  border: 1px solid #c3c3c3;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.address-span {
  grid-column: span 2;
}
.custom-modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -5;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}
.custom-modal-container.active {
  transform: translate(-50%, -50%);
  opacity: 1;
  pointer-events: all;
  z-index: 9;
}
.custom-modal-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5019607843);
}
.custom-modal-box {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-modal-box .header,
.custom-modal-box .body,
.custom-modal-box .footer {
  background: #fff;
}
.custom-modal-box .header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.custom-modal-box .footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.change-type {
  border-bottom: 1px solid #c3c3c3;
  padding: 20px 0;
  display: grid;
  grid-template-columns: 70% 20%;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.change-type p {
  text-transform: capitalize;
}
.first-change-type {
  border-top: 1px solid #c3c3c3;
}
.smile-div svg {
  font-size: 2rem;
}
.star-div svg {
  font-size: 1.5rem;
  cursor: pointer;
  color: gold;
}
.my-accordion .my-accordion-body {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.my-accordion .my-accordion-body.active {
  max-height: 999px;
  transition: var(--transition);
}
.tandclist li,
.tandclist h3 {
  margin-bottom: 8px;
}

.tandclist h3 {
  margin-top: 20px;
}
.store-image {
  width: 140px;
  height: auto;
  display: block;
}
.invert-image {
  filter: invert(1);
}
.login-link {
  font-weight: bold;
  display: inline-block;
  color: var(--secondary);
}
.assistance {
  width: 100%;
  background: var(--gradient);
  color: #fff;
  padding: 8px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.assistance .assistance-content {
  transform: translateY(100%);
  text-align: center;
  font-weight: bolder;
}
.assistance .advocate {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid var(--grey);
  transform: translateY(50%);
}
.assistance .advocate img {
  aspect-ratio: 1;
}
.enroll-div {
  max-width: 576px;
  margin: 0 auto;
}
.assistance-seperator {
  height: 100px;
}
.enroll-introduction .enroll-card {
  background: #fff;
  padding: 16px 8px;
  text-align: center;
  margin-bottom: 8px;
  border-radius: var(--radius);
  position: relative;
  box-shadow: var(--shadow);
}
.enroll-introduction .enroll-card .enroll-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}
.enroll-introduction .enroll-card .enroll-header img {
  width: 75px;
}
.enroll-introduction .enroll-card .enroll-header h1 {
  color: var(--secondary);
}
.enroll-card .enroll-number {
  background: var(--gradient);
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  color: #fff;
  border-radius: 50%;
}
.enroll-pink-box {
  padding: 24px 8px;
  background: var(--gradient);
  color: #fff;
  text-align: center;
  border-radius: var(--radius);
}
.primary-text {
  color: var(--primary);
}
.description-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.description-grid .description-card {
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: var(--radius);
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  box-shadow: var(--shadow);
}
.description-card .description-img {
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;

  margin: 0 auto;
}
.description-button {
  text-align: center;
  width: fit-content;
  margin: 16px auto 0;
}
.plan-card {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  background: var(--gradient);
  border-radius: var(--radius);
  color: #fff;
  text-align: center;
  padding: 8px;
  box-shadow: var(--shadow);
}
.plan-middle {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  gap: 8px;
  padding: 8px 0;
}
.plan-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plan-button {
  width: fit-content;
  margin: 0 auto;
  background: #fff;
  color: var(--primary);
}
.offer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.offer-card {
  width: 600px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 8px;
  margin: 0 auto 16px;
}
.offer .offer-card:nth-child(odd) {
  transform: translateX(100px);
  grid-template-columns: 1fr 3fr;
}
.offer .offer-card:nth-child(even) {
  transform: translateX(-100px);
  grid-template-columns: 3fr 1fr;
}
.offer .offer-card:nth-child(even) .offer-img {
  order: 2;
}
.offer .offer-card:nth-child(even) .offer-body {
  order: 1;
}
.enroll-container {
  padding: 8px;
  border-radius: var(--radius);
}
.enroll-conditions {
  height: 500px;
  overflow-y: auto;
  box-shadow: var(--shadow);
  padding: 8px;
  border-radius: var(--radius);
}
.label-flex,
.list-item-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  pointer-events: all;
}
.list-item-flex svg {
  color: var(--primary);
  min-width: 20px;
  min-height: 20px;
}
.payment-details-container {
  background: var(--gradient);
  padding: 24px 8px;
  color: #fff;
  border-radius: var(--radius);
}
.payment-details-container .payment-details-box {
  max-width: 500px;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: #ffffff0e;
}
.payment-details-button {
  background: #fff;
  color: var(--primary);
}
.pink-loader {
  color: var(--primary);
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
.recommended {
  background: rgb(0, 190, 0);
  padding: 4px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: inline-block;
  width: fit-content;
  margin-left: auto;
  animation: recommended 0.75s alternate-reverse infinite;
}
.recommended p {
  font-size: 14px !important;
  color: #fff;
}
@keyframes recommended {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.enroll-button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.enroll-button-flex .button {
  min-width: 80px;
  text-align: center;
}
.modal-button-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  background: var(--gradient);
  border-radius: var(--radius) !important;
  width: 25px !important;
  height: 25px !important;
  box-shadow: var(--shadow);
  top: unset !important;
  bottom: 0 !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 15px !important;
}
.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-next {
  right: 0 !important;
}
.swiper-slide {
  transform: scale(0.9) !important;
  opacity: 0.9 !important;
  transition: var(--transition);
}
.swiper-slide-next {
  transform: scale(1) !important;
  opacity: 1 !important;
}
.payment-success {
  max-width: 500px;
  margin: 0 auto;
}
.laptop-boi {
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: var(--shadow);
}
.payment-list-items {
  max-width: 550px;
  margin: 20px auto;
}
.swiper-wrapper {
  padding-bottom: 30px;
}
.golden-box {
  background: linear-gradient(to top, #ff9408, #ffbe0a);
  max-width: 500px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  margin: 16px auto;
  padding: 16px 8px;
  color: #fff;
}
.golden-box h1 {
  border-bottom: 2px solid #fff;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.goldren-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.goldren-flex img {
  width: 40px;
  aspect-ratio: 1;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*  */
.signup2-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--gradient);
}
.lady-div {
  max-width: 400px;
  align-self: flex-end;
}
.content-div {
  flex: 1;
  color: #fff;
}
.content-div img {
  width: 200px;
}
.form-div {
  flex: 1;
}
.signup-text {
  border-radius: var(--radius);
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  display: inline-block;
}
.signup2-container .fw-bold {
  font-weight: bolder !important;
  font-size: 16px;
}
.form-box {
  background: #fff;
  max-width: 400px;
  padding: 30px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
}
.form-div .form-label {
  background: #fff;
}
.creditors-grid-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signin-icon-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}
.signin-icon-flex img {
  width: 25px;
  height: 25px;
}
.store-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.store-flex img {
  max-width: 120px;
}
.tc-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  list-style: none;
  flex-wrap: wrap;
}
